import { makeStyles } from 'tss-react/mui'

export const useCheckoutStyles = makeStyles()((theme) => ({
  mainContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "'tt-talent'",
  },
  pageContainer: {
    flex: 1,
    backgroundColor: theme.colors.neutral[10],
    height: '100%',

    '& *': {
      fontFamily: "'tt-talent'",
      fontWeight: '400',
    },
  },
}))
