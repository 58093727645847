import React, { ComponentProps } from 'react'
import { Box, styled } from '@mui/material'

const InputLabel = styled('label')({
  fontFamily: "'tt-talent', Helvetica, Arial, sans-serif",
  fontWeight: 400,
  fontSize: '14px',
  marginBottom: 3,
  letterSpacing: 0.2,
  color: '#828BA2',
})

const Input = styled('input')({
  fontSize: 17,
  fontFamily: "'tt-talent', Helvetica, Arial, sans-serif",
  color: 'rgb(51, 63, 87)',
  height: 42,
  borderRadius: '6px',
  border: '1px solid transparent',
  backgroundColor: 'rgb(246, 247, 250)',
  textIndent: 5,
  outline: 'none',
  '&:hover': {
    borderColor: '#aebad3',
  },
  '&:focus': {
    outline: 'none',
  },
})

const HelperText = styled('p')({
  background: '#ff0',
  color: '#202124',
  padding: '2.5px 5px',
  width: 'fit-content',
  fontSize: 12,
  fontWeight: 600,
  marginTop: 6,
})

type Props = ComponentProps<'input'> & {
  label: string
  helperText?: string
}

export const TextInput = (props: Props) => {
  const { label, helperText, ...inputProps } = props

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <InputLabel>{label}</InputLabel>
      <Input {...inputProps} />
      {helperText ? <HelperText>{helperText}</HelperText> : null}
    </Box>
  )
}

TextInput.Wrap = styled(Box)({
  display: 'flex',
  gap: 15,
  flex: 1,
  flexWrap: 'nowrap',

  '@media (max-width: 425px)': {
    flexWrap: 'wrap',
  },
})
