import React from 'react'
import { Box, styled } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'

const Root = styled('span')({
  fontFamily: "'tt-talent', Helvetica, Arial, sans-serif",
  fontWeight: 800,
  fontSize: 12,
  textTransform: 'uppercase',
  letterSpacing: 2,
  color: '#1E2532',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
})

type Props = {
  items: string[]
}

export const Breadcrumb = (props: Props) => {
  const { items } = props

  return (
    <Root>
      {items.slice(0, items.length - 1).map((i) => (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyItems="center"
          height={15}
          key={i}
        >
          <span style={{ fontWeight: 500 }}>{i}</span>
          <ChevronRight
            fontSize="small"
            style={{ position: 'relative', top: -1, height: '18px' }}
          />
        </Box>
      ))}
      <span style={{ opacity: 0.4 }}>{items.at(-1)}</span>
    </Root>
  )
}
