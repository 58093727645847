import { Checkbox as MuiCheckbox, styled } from '@mui/material'
import React, { ReactNode } from 'react'

const TextLabel = styled('span')({
  color: '#09143b',
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: '2px',
  marginLeft: 5,
  cursor: 'pointer',
  userSelect: 'none',
  position: 'relative',
  top: 1,
})

type Props = {
  label: string | ReactNode
  id: string
  value: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export const Checkbox = (props: Props) => {
  const { label, id, value, onChange } = props

  return (
    <label
      htmlFor={id}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyItems: 'center',
      }}
    >
      <MuiCheckbox
        disableRipple
        color="primary"
        id={id}
        checked={value}
        onChange={onChange}
        size="small"
        style={{ padding: 0 }}
      />

      <TextLabel>{label}</TextLabel>
    </label>
  )
}
