import React, { useState } from 'react'
import { Box, Button, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ChevronLeft } from '@mui/icons-material'
import ComplianceNote from '@talentinc/gatsby-theme-ecom/components/ComplianceNote/ComplianceNote'
import ComplianceNoteProvider, {
  useComplianceNote,
} from '@talentinc/gatsby-theme-ecom/hooks/useComplianceNote'
import { WidgetTRefs } from '@talentinc/gatsby-theme-ecom/types/widget'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Checkbox } from './Checkbox'

const ComplianceNoticeSingle = () => {
  const { t } = useTranslation()
  const { handleComplianceModalOpen } = useComplianceNote()

  return (
    <ComplianceNote>
      <ComplianceNote.TextLink
        style={{
          textTransform: 'uppercase',
          letterSpacing: '2px',
          fontSize: 12,
          textDecoration: 'none',
        }}
        onClick={(e: any) => {
          e.stopPropagation()
          handleComplianceModalOpen(e)
        }}
      >
        {t(`${WidgetTRefs.CommonTRef}.tos`)}
      </ComplianceNote.TextLink>
    </ComplianceNote>
  )
}

type Props = {
  loading?: boolean
  disabled?: boolean
  onClickSubmit?: React.MouseEventHandler<HTMLButtonElement>
  onClickGoBack?: React.MouseEventHandler<HTMLButtonElement>
  brandName?: string
}

const style = makeStyles()((theme) => ({
  purchaseForm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginTop: '20px',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column-reverse',
    },
  },
  purchaseButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export function CheckoutFormSubmit({
  loading,
  disabled = false,
  onClickGoBack,
  onClickSubmit,
  brandName,
}: Props) {
  const theme = useTheme()
  const [terms, setTerms] = useState(true)
  const { classes, cx } = style()

  return (
    <ComplianceNoteProvider>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          gap="1px"
          alignItems="center"
          justifyItems="center"
        >
          <Checkbox
            id="terms"
            value={terms}
            onChange={() => setTerms((prev) => !prev)}
            label={<>by placing your order, you agree to {brandName}'s </>}
          />
          <Box ml="3px">
            <ComplianceNoticeSingle />.
          </Box>
        </Box>

        <Box className={classes.purchaseForm}>
          <Button onClick={onClickGoBack} variant="text" style={{ padding: 0 }}>
            <Box
              style={{
                color: 'rgb(51, 63, 87)',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gridGap: 3,
              }}
              sx={{
                [theme.breakpoints.down('sm')]: {
                  marginTop: '30px',
                },
              }}
            >
              <ChevronLeft
                fontSize="small"
                style={{
                  position: 'relative',
                  top: -1,
                }}
              />
              <span
                style={{
                  fontSize: 17,
                  lineHeight: 1,
                }}
              >
                Back To Services
              </span>
            </Box>
          </Button>
          <Button
            disabled={loading || !terms || disabled}
            style={{
              borderRadius: 5,
              fontSize: 18,
              height: 45,
              fontFamily: 'tt-talent',
            }}
            type="button"
            className={cx(classes.purchaseButton, 'purchase-button')}
            onClick={onClickSubmit}
          >
            Complete Order
          </Button>
        </Box>
      </Box>
    </ComplianceNoteProvider>
  )
}
