import React, { CSSProperties } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import affirmBlackLogo from '@talentinc/gatsby-theme-ecom/images/affirm_black_logo.svg'

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  style?: CSSProperties
  disabled?: boolean
}

export const ButtonPayOverTime = (props: Props) => {
  const { onClick, style = {}, disabled = false } = props
  const { classes } = useStyles()
  const { isTopCV, isTopCVuk } = useBrand()

  if (isTopCV || isTopCVuk) return null

  return (
    <button
      disabled={disabled}
      id="affirm-btn"
      type="button"
      className={classes.root}
      onClick={(e) => {
        e.preventDefault()
        !!!disabled && onClick(e)
      }}
      style={style}
    >
      <Box display="flex" flexDirection="row" gap="22px">
        <img src={affirmBlackLogo} className={classes.logo} alt="Affirm logo" />
        <span className={classes.text}>Pay monthly with Affirm</span>
      </Box>
    </button>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    cursor: 'pointer',
    border: '1px solid #BEC4D5',
    background: '#F7F9FC',
    color: 'black',
    borderRadius: 5,
    width: '100%',
    opacity: 1,
    transition: 'background-color 0.1s',
    padding: '17px 12px',

    '&:disabled': {
      opacity: 0.5,

      '&:hover': {
        cursor: 'not-allowed',
      },
    },

    '&:hover': {
      backgroundColor: '#e3e9ed',
    },
  },
  logo: {
    height: 17,
  },
  text: {
    fontWeight: 400,
    fontSize: '17px',
  },
}))
