import dropin from 'braintree-web-drop-in'
import { ButtonStyle } from 'paypal-checkout-components'
import { useCallback, useEffect, useState } from 'react'
import * as logger from '../../utils/logging'
import { useClientToken } from './queries'
import { CalculatePricesResponse } from './types'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'

const createDropinInstance = async ({
  container,
  clientToken,
  planPricesData,
  disable,
}: {
  container: string
  clientToken?: string
  planPricesData: CalculatePricesResponse | undefined
  disable: Record<string, boolean>
}): Promise<dropin.Dropin | null> => {
  return new Promise((resolve, reject) => {
    const { planPrices, totalPrice } = planPricesData || {}

    if (!totalPrice) {
      logger.error('Invalid plan prices:', planPrices)
      return reject('Invalid plan prices')
    }

    const firstPlan = planPrices?.[0]

    const planNames = planPrices?.map((p) => p.planName).join(' + ')

    if (!firstPlan) {
      logger.error('Invalid first plan:', firstPlan)
      return reject('Invalid first plan')
    }

    const merchantDisplayName = 'Talent Inc.'
    const paymentAmount = String(totalPrice)
    const currencyCode = firstPlan?.currencyCode || 'USD'

    dropin.create(
      {
        authorization: clientToken as string,
        container,
        dataCollector: true,
        paymentOptionPriority: [
          'card',
          'applePay',
          'googlePay',
          'paypal',
          'venmo',
        ],
        applePay: {
          displayName: merchantDisplayName,
          // @ts-ignore
          paymentRequest: {
            total: {
              label: planNames || merchantDisplayName,
              amount: paymentAmount,
            },
            countryCode: 'US',
            currencyCode: currencyCode,
          },
        },
        paypal: {
          flow: 'vault',
          buttonStyle: {
            color: 'blue',
            shape: 'rect',
            size: 'medium',
          } as ButtonStyle,
        },
        googlePay: {
          googlePayVersion: 2,
          merchantId: process.env.GATSBY_GOOGLE_PAY_MERCHANT_ID || '',
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPrice: paymentAmount,
            currencyCode: currencyCode,
          },
        },
        card: {
          cardholderName: { required: true },
          overrides: {
            fields: {
              number: { placeholder: '' },
              expirationDate: { placeholder: '' },
              cvv: { placeholder: '' },
              postalCode: { placeholder: '' },
              // @ts-ignore
              cardholderName: { placeholder: '' },
            },
            styles: {
              input: {
                color: 'rgb(51, 63, 87)',
                'font-family': "'tt-talent', Helvetica, Arial, sans-serif",
                'font-size': '15px',
              },
              '.invalid': {},
            },
          },
        },
        ...(!disable?.venmo && {
          venmo: {
            // @ts-ignore
            allowDesktopWebLogin: true,
            mobileWebFallBack: true,
            paymentMethodUsage: 'multi_use',
            profileId: process.env.GATSBY_VENMO_PROFILE_ID || '',
          },
        }),
      },
      (dropinErr, dropinInstance) => {
        if (dropinErr) {
          logger.error(dropinErr)
          return reject(dropinErr)
        }

        if (dropinInstance) {
          return resolve(dropinInstance)
        }

        return reject(dropinErr)
      }
    )
  })
}

const elementHasContent = (el: string) => document.querySelector(el)?.innerHTML

export function useDropin(el: string, options: any) {
  const { data: clientToken } = useClientToken()
  const { isTopResume } = useBrand()

  const [dropinInstance, setDropinInstance] = useState<dropin.Dropin | null>(
    null
  )
  const [instanceCreateError, setInstanceCreateError] = useState(null)

  const mountDropin = useCallback(async () => {
    if (!clientToken || !options.pricesProducts) {
      return
    }

    try {
      if (elementHasContent(el)) return

      const dropin = await createDropinInstance({
        container: el,
        clientToken,
        planPricesData: options.pricesProducts,
        disable: {
          venmo: !isTopResume,
        },
      })

      if (!dropin) {
        throw new Error('Error creating DropIn instance.')
      }

      setDropinInstance(dropin)
      setInstanceCreateError(null)
    } catch (e) {
      setInstanceCreateError(e as any)
      logger.error('Error initializing BrainTree:', e)
    }
  }, [
    el,
    clientToken,
    options.pricesProducts,
    setInstanceCreateError,
    isTopResume,
  ])

  const unmountDropin = useCallback(() => {
    setDropinInstance((current) => {
      current?.teardown?.()
      return null
    })
  }, [setDropinInstance])

  useEffect(() => {
    if (dropinInstance) return
    mountDropin()
  }, [mountDropin, dropinInstance, clientToken, options.pricesProducts])

  const requestPaymentMethod: () => Promise<dropin.PaymentMethodPayload> =
    useCallback(
      () =>
        new Promise((resolve, reject) => {
          dropinInstance?.requestPaymentMethod((err, payload) =>
            err ? reject(err) : resolve(payload)
          )
        }),
      [dropinInstance]
    )

  return {
    requestPaymentMethod,
    dropinInstance,
    instanceCreateError,
    mountDropin,
    unmountDropin,
  }
}

///////
