export const localizedCurrency = (currencyCode?: string, price?: number) => {
  if (!currencyCode) return price
  if (!price) return ''

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 0,
  }).format(price)
}

export const getTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (e) {
    return null
  }
}

export const camel2snake = (str: string) =>
  str
    .split(/\.?(?=[A-Z])/)
    .join('_')
    .toLowerCase()

export const isURLNew = (pathname: string) => pathname.includes('/new')
