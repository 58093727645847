type AffirmCheckoutArgs = {
  user: {
    name: string
    email: string
  }
  items: Array<{
    sku: string
    displayName: string
    unitPrice: number
  }>
  total: number
  currency: string
}
export const affirmCheckout = (args: AffirmCheckoutArgs) => {
  window.affirm.checkout({
    metadata: { mode: 'modal' },
    shipping: {
      name: { full: args.user.name },
      email: args.user.email,
    },
    billing: {
      name: { full: args.user.name },
      email: args.user.email,
    },
    items: args.items.map((i) => ({
      sku: i.sku,
      display_name: i.displayName,
      unit_price: i.unitPrice,
      qty: 1,
    })),
    currency: 'USD',
    tax_amount: 0,
    total: args.total,
    merchant: {
      public_api_key: process.env.GATSBY_AFFIRM_PUBLIC_KEY,
      use_vcn: false,
      name: 'Talent Inc.',
      // NOTE: affirm requires this url's even in mode 'modal'
      user_confirmation_url: 'https://merchantsite.com/purchase',
      user_cancel_url: 'https://merchantsite.com/cancel',
    },
  })
}

export type AffirmOpenArgs = {
  onSuccess: (args: { checkout_token: string; created: string }) => void
  onFail?: (args: { checkout_token: string; reason: string }) => void
}

export const affirmOpen = (args: AffirmOpenArgs) => {
  window.affirm.checkout.open({
    onSuccess: args.onSuccess,
    onFail: args.onFail,
    onValidationError: args.onFail,
  })
}
