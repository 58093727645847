import { OrderResponse } from '@talentinc/gatsby-theme-ecom/components/NewCheckout/types'
import Cookies from 'js-cookie'

const COOKIE_KEY = 'gtm_tracked_transactions'

export const tagPurchaseGTMEvent = (
  order: OrderResponse,
  location: { pathname?: string; hostname?: string },
  event = 'Purchase'
) => {
  const cookieTrackedTransactions = JSON.parse(Cookies.get(COOKIE_KEY) || '[]')
  const trackedTransactions = new Set(cookieTrackedTransactions)
  const isNewTransaction = !trackedTransactions.has(order.transactionId)

  if (isNewTransaction) {
    trackedTransactions.add(order.transactionId)
    Cookies.set(COOKIE_KEY, JSON.stringify(Array.from(trackedTransactions)), {
      expires: 365,
      domain: location?.hostname,
      path: location?.pathname,
    })
  }

  const gtmData = {
    transactionId: order.transactionId,
    transactionAffiliation: order.brandName,
    transactionTotal: order.totalUSD,
    transactionTotalInCents: order.totalUSD * 100,
    transactionCurrency: order.currency,
    transactionTotalInCurrency: order.products.totalPrice,
    transactionTotalInCurrencyCents: order.products.totalPrice * 100,
    transactionProducts: order.products.planPrices.map((plan) => ({
      sku: plan.planCode,
      name: plan.planName,
      category: 'N/A',
      price: plan.price,
      quantity: 1,
    })),
    hashedEmail: order.lead.hashedEmail,
    userLeadAge: order.lead.age,
    userLeadScore: order.lead.score,
    event,
    ...(order.partnerId && { partnerId: order.partnerId }),
  }

  return {
    gtmData,
    isNewTransaction,
  }
}
